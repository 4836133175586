import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-320fa752"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-dashboard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeDashboardSection = _resolveComponent("HomeDashboardSection")!
  const _component_Main = _resolveComponent("Main")!

  return (_openBlock(), _createBlock(_component_Main, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_HomeDashboardSection)
      ])
    ]),
    _: 1
  }))
}