import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41022646"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "home-dashboard-section"
}
const _hoisted_2 = {
  key: 0,
  class: "home-dashboard-section__wrap"
}
const _hoisted_3 = { class: "home-dashboard-section__block-wrap" }
const _hoisted_4 = { class: "home-dashboard-section__icon" }
const _hoisted_5 = { class: "home-dashboard-section__block" }
const _hoisted_6 = { class: "home-dashboard-section__block-title" }
const _hoisted_7 = { class: "home-dashboard-section__block-number" }
const _hoisted_8 = { class: "home-dashboard-section__block-wrap" }
const _hoisted_9 = { class: "home-dashboard-section__icon" }
const _hoisted_10 = { class: "home-dashboard-section__block" }
const _hoisted_11 = { class: "home-dashboard-section__block-title" }
const _hoisted_12 = { class: "home-dashboard-section__block-number" }
const _hoisted_13 = { class: "home-dashboard-section__block-wrap" }
const _hoisted_14 = { class: "home-dashboard-section__icon" }
const _hoisted_15 = { class: "home-dashboard-section__block" }
const _hoisted_16 = { class: "home-dashboard-section__block-title" }
const _hoisted_17 = { class: "home-dashboard-section__block-number" }
const _hoisted_18 = { class: "home-dashboard-section__block-wrap" }
const _hoisted_19 = { class: "home-dashboard-section__icon" }
const _hoisted_20 = { class: "home-dashboard-section__block" }
const _hoisted_21 = { class: "home-dashboard-section__block-title" }
const _hoisted_22 = { class: "home-dashboard-section__block-number" }
const _hoisted_23 = { class: "home-dashboard-section__block-wrap" }
const _hoisted_24 = { class: "home-dashboard-section__icon" }
const _hoisted_25 = { class: "home-dashboard-section__block" }
const _hoisted_26 = { class: "home-dashboard-section__block-title" }
const _hoisted_27 = { class: "home-dashboard-section__block-number" }
const _hoisted_28 = { class: "home-dashboard-section__block-wrap" }
const _hoisted_29 = { class: "home-dashboard-section__icon" }
const _hoisted_30 = { class: "home-dashboard-section__block" }
const _hoisted_31 = { class: "home-dashboard-section__block-title" }
const _hoisted_32 = { class: "home-dashboard-section__block-number" }
const _hoisted_33 = {
  key: 1,
  class: "home-dashboard-section__wrap-payment"
}
const _hoisted_34 = { class: "home-dashboard-section__replenish" }
const _hoisted_35 = { class: "home-dashboard-section__arrow-bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bag = _resolveComponent("Bag")!
  const _component_Group = _resolveComponent("Group")!
  const _component_Net = _resolveComponent("Net")!
  const _component_Win = _resolveComponent("Win")!
  const _component_Withdraw = _resolveComponent("Withdraw")!
  const _component_Have = _resolveComponent("Have")!
  const _component_ArrowBottom = _resolveComponent("ArrowBottom")!
  const _component_MainButton = _resolveComponent("MainButton")!
  const _component_RefLink = _resolveComponent("RefLink")!

  return (this.translateData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (this.statistics)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_Bag)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(this.translateData.my_deposit), 1),
                  _createElementVNode("div", _hoisted_7, "$" + _toDisplayString(this.statistics.deposit), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_Group)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(this.translateData.attracted_people), 1),
                  _createElementVNode("div", _hoisted_12, _toDisplayString(this.statistics.referrals_count), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_Net)
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, _toDisplayString(this.translateData.total_in_my_network), 1),
                  _createElementVNode("div", _hoisted_17, _toDisplayString(this.statistics.total_network_referrals_count), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_component_Win)
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, _toDisplayString(this.translateData.earnings), 1),
                  _createElementVNode("div", _hoisted_22, "$" + _toDisplayString(this.statistics.income), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createVNode(_component_Withdraw)
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("div", _hoisted_26, _toDisplayString(this.translateData.withdrawn), 1),
                  _createElementVNode("div", _hoisted_27, "$" + _toDisplayString(this.statistics.withdrawn), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _createVNode(_component_Have)
                ]),
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("div", _hoisted_31, _toDisplayString(this.translateData.for_withdrawal), 1),
                  _createElementVNode("div", _hoisted_32, "$" + _toDisplayString(this.statistics.available_for_conclusion), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (!this.hasPaymentNew && !this.hasPayment.has_payments)
          ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
              _createElementVNode("div", _hoisted_34, _toDisplayString(this.translateData.top_up_70_text), 1),
              _createElementVNode("div", _hoisted_35, [
                _createVNode(_component_ArrowBottom)
              ]),
              _createVNode(_component_MainButton, {
                class: "home-dashboard-section__button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToPayment()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(this.translateData.top_up_70_button), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_RefLink)
      ]))
    : _createCommentVNode("", true)
}